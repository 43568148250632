import type { JSX } from 'react';
import { createContext, useContext, useMemo } from 'react';
import type { DiveContextProviderProps, DiveState } from '../types/Dive.types';
import { DiveDevice } from '../types/Dive.types';

export const DEFAULT_STATE = {
  isTv: false,
  device: DiveDevice.Web,
} satisfies DiveState;

const DiveContext = createContext<DiveState>(DEFAULT_STATE);

/**
 * DIVE context for rendering TV-specific styles.
 *
 * It is expected to be wrapped around the consuming App's entry point and initialized with an `isTv` prop.
 *
 * @example
 * import { DiveContextProvider } from '@canalplus/dive';
 *
 * const isTv = true; // your device detection logic here
 * <DiveContextProvider isTv={isTv}>
 *   <App />
 * </DiveContextProvider>
 */
function DiveContextProvider({
  children,
  isTv = false,
}: DiveContextProviderProps): JSX.Element {
  const value = useMemo(() => {
    return {
      isTv,
      device: isTv ? DiveDevice.Tv : DiveDevice.Web,
    };
  }, [isTv]);

  return <DiveContext.Provider value={value}>{children}</DiveContext.Provider>;
}

/**
 * A hook that returns the DIVE context keys:
 * - `device` - The type of `DiveDevice` the app is rendered on (e.g.: `"web"`, `"tv"`)
 * - `isTv` - A boolean indicating if the app is rendered on a TV
 *
 * @example
 * import { useDiveContext } from '../../context';
 *
 * export function DiveComponent() {
 *   const { isTv } = useDiveContext();
 *   // ...
 * }
 */
function useDiveContext(): DiveState {
  return useContext(DiveContext);
}

export { DiveContextProvider, useDiveContext };
