import classNames from 'classnames';
import { forwardRef, useEffect, useRef, useState, type JSX } from 'react';
import { throttle } from '../../../helpers/helpers';
import type { DiveProps } from '../../../types/Dive.types';
import { DiveBreakpoint } from '../../../types/Dive.types';
import { Button } from '../Button/Button';
import type { ButtonProps } from '../Button/Button.types';
import type { UseTruncatedDetectorProps } from './useIsOverflowing';
import { useIsOverflowing } from './useIsOverflowing';

export type ButtonRetractableProps = {
  /**
   * The label to be displayed.
   */
  label: string;
  /**
   * Function to compute the available space in the container. Up to the consumer to
   * calculate the available space based on the container and its children.
   * @returns The available space in the container
   * @default () => 0
   */
  computeAvailableSpace?: UseTruncatedDetectorProps['computeAvailableSpace'];
} & ButtonProps &
  DiveProps;

/**
 * Button component that retracts the label into icon only when it overflows on mobile devices.
 * It wraps a `<Button />` component with the appropriate styles.
 *
 * @example
 *
 * ```tsx
 *   <ButtonRetractable
 *     ref={ref}
 *     label="Subscribe"
 *     onClick={() => {}}
 *     computeAvailableSpace={() => ...}
 *  />
 * ```
 */
export const ButtonRetractable = forwardRef<
  HTMLButtonElement,
  ButtonRetractableProps
>(function ButtonRetractable(
  {
    label,
    className,
    icon,
    computeAvailableSpace,
    ...rest
  }: ButtonRetractableProps,
  forwardedRef
): JSX.Element {
  const [isMobile, setIsMobile] = useState(false);
  const subscribeLabelRef = useRef<HTMLSpanElement>(null);

  /* Detect if the device is mobile on mount and on resize */
  useEffect(() => {
    const detectIsMobile = throttle(() => {
      setIsMobile(window.innerWidth < DiveBreakpoint.Sm);
    }, 250);
    detectIsMobile();
    window.addEventListener('resize', detectIsMobile);
    return () => {
      detectIsMobile.cancel();
      window.removeEventListener('resize', detectIsMobile);
    };
  }, []);

  const isOverflowingOnMobile = useIsOverflowing({
    ref: subscribeLabelRef,
    enabled: isMobile,
    computeAvailableSpace,
  });

  return (
    <Button
      {...rest}
      ref={forwardedRef}
      className={classNames(className, {
        'min-w-fit dt-breakpoint-sm-start:min-w-auto': isOverflowingOnMobile,
      })}
      {...(isOverflowingOnMobile && { icon })}
    >
      <span
        ref={subscribeLabelRef}
        className={classNames(
          'truncate dt-breakpoint-sm-start:text-clip dt-breakpoint-sm-start:!mx-dt-spacing-none !shrink dt-breakpoint-sm-start:shrink-0',
          {
            'hidden dt-breakpoint-sm-start:flex': isOverflowingOnMobile,
          }
        )}
      >
        {label}
      </span>
    </Button>
  );
});
